/* eslint-disable camelcase */
import { Link } from "gatsby"
import React from "react"
import { toast } from "react-toastify"
import styled from "styled-components"
import { BiVideo } from "@react-icons/all-files/bi/BiVideo"
import { BiTask } from "@react-icons/all-files/bi/BiTask"
import { BiLockAlt } from "@react-icons/all-files/bi/BiLockAlt"
import { BiNote } from "@react-icons/all-files/bi/BiNote"

const Styles = styled.div`
  max-height: 24rem;
  overflow-y: auto;

  a {
    display: block;
    color: black;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 4px;
  }

  a:hover {
    background: #ffce00;
  }

  .link-container {
    display: flex;
    gap: 1rem;
    align-items: center;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .chosen-kelas {
    background: #f4b71a;
    color: white;
  }
`

export default function MyKelasList({ chapters, courseId, beli, chosen }) {
  return (
    <Styles>
      {chapters.map((chapter, cIndex) => (
        <div key={cIndex}>
          {chapter?.items
            .filter(item => item?.status === true)
            .map((item, iIndex) =>
              beli ? (
                <Link
                  key={iIndex}
                  to={`${
                    item.type === "video"
                      ? `/course-video?id=${courseId}&v=${item.id}`
                      : item.type === "quiz"
                      ? `/quiz?course=${courseId}&id=${item.id}`
                      : item.type === "note"
                      ? `/course/notes/${courseId}/${item.id}`
                      : `/course/${courseId}`
                  }`}
                  className={
                    chosen && chosen === item?.id ? `chosen-kelas` : ``
                  }
                  style={{ textDecoration: "none" }}
                >
                  <div className="link-container">
                    {item.type === "video" && (
                      <BiVideo style={{ flexShrink: 0 }} />
                    )}
                    {item.type === "quiz" && (
                      <BiTask style={{ flexShrink: 0 }} />
                    )}
                    {item.type === "note" && (
                      <BiNote style={{ flexShrink: 0 }} />
                    )}
                    <div>
                      {item.type === "video" && item.video.title}
                      {item.type === "quiz" && "Quiz"}
                      {item.type === "note" && "Catatan Tutor"}
                    </div>
                  </div>
                </Link>
              ) : (
                <div
                  onClick={() =>
                    toast("Maaf, Anda perlu membeli kursus terlebih dahulu")
                  }
                  style={{ textDecoration: "none" }}
                  className={
                    chosen && chosen === item?.id ? `chosen-kelas` : ``
                  }
                  key={iIndex}
                >
                  <a style={{ cursor: "pointer" }} className="link-container">
                    <BiLockAlt style={{ flexShrink: 0 }} />
                    <div>
                      {item.type === "video" && item.video.title}
                      {item.type === "quiz" && "Quiz"}
                      {item.type === "note" && "Catatan Tutor"}
                    </div>
                  </a>
                </div>
              )
            )}
        </div>
      ))}
    </Styles>
  )
}
