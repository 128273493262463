import React, { useState, useEffect } from "react"
import MyKelasList from "../CourseComponent/kelas-list"
import CourseProgress from "../CourseVideoComponent/progress"
import { Container, LeftContainer, RightContainer } from "./styles"
import { getCourseProgress } from "../../services/course"
import { StyledCourseDescWrap } from "../CourseVideoComponent/style"

const NoteComponent = ({ course, itemId }) => {
  const [note, setNote] = useState({})
  const [progress, setProgress] = useState(-1)
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setNote(getNote())
    getCourseProgress(course.id).then(resp =>
      setProgress(resp.data?.percentage)
    )
  }, [])

  const getNote = () => {
    for (const chapter of course?.chapters) {
      for (const item of chapter?.items) {
        if (item?.id === parseInt(itemId)) {
          setLoading(false)
          return item?.note
        }
      }
    }
    return false
  }

  return (
    <Container>
      <LeftContainer>
        <h2>Catatan Tutor</h2>
        <div
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{
            __html: note.description_html,
          }}
        ></div>
      </LeftContainer>
      <RightContainer>
        <h4 className="judul-content-right course-desc-wrap">{course.title}</h4>
        {progress >= 0 && (
          <StyledCourseDescWrap>
            <CourseProgress progress={progress} courseId={course.id} />
          </StyledCourseDescWrap>
        )}
        <div className="class-overview">
          <div className="daftar-isi">
            <div>
              <MyKelasList
                chapters={course.chapters}
                courseId={course.id}
                beli={course.is_owned}
                chosen={parseInt(itemId)}
              />
            </div>
          </div>
        </div>
      </RightContainer>
    </Container>
  )
}

export default NoteComponent
