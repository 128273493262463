import styled from "styled-components"

export const CourseVideoStyles = styled.div`
  .course-vid-wrapper {
    display: flex;
  }

  .course-vid-left {
    width: 60%;
    padding: 15px;

    .judul-vid {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    video {
      width: 100%;
    }
  }

  .course-vid-right {
    width: 40%;
    padding: 15px;

    @media only screen and (max-width: 991.98px) {
      display: none;
    }

    .class-overview {
      border-radius: 8px;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

      .judul-content-right {
        padding: 16px;
        padding-bottom: 0;
        text-align: center;
      }

      .tutor-summary {
        padding-left: 6px;
        padding-right: 6px;
      }
    }

    .daftar-isi {
      h4 {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  .course-desc-wrap {
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    margin-top: 10px;
    margin-bottom: 15px;

    & > h2,
    & > p {
      padding: 0 15px;
    }

    & > h2 {
      padding-top: 10px;
      padding-bottom: 16px;
      border-bottom: 2px solid #dddddd;
    }

    & > p {
      padding-top: 12px;
    }
  }

  @media only screen and (max-width: 991.98px) {
    .course-vid-wrapper {
      flex-wrap: wrap;
    }

    .course-vid-left {
      width: 100%;
      padding-bottom: 5px;
    }

    .course-vid-right {
      width: 100%;
      padding-top: 5px;
    }
  }

  @media only screen and (min-width: 991.98px) {
    .mobile-only {
      display: none;
    }
  }
`

export const StyledCourseDescWrap = styled.div`
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  margin-top: 10px;
  margin-bottom: 15px;

  & > h2,
  & > p {
    padding: 0 15px;
  }

  & > h2 {
    padding-top: 10px;
    padding-bottom: 16px;
    border-bottom: 2px solid #dddddd;
  }

  & > p {
    padding-top: 12px;
  }
`
