import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  padding: 2rem 1rem;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`

export const LeftContainer = styled.div`
  width: 60%;
  padding: 0 2rem;

  .progress-bar {
    background: #efa51d;
  }

  .pertanyaan-counter {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }

  .pertanyaan {
    margin-top: 1rem;
    font-size: 1.25rem;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .option-container {
    width: 100%;
  }

  .option {
    height: 2rem;
    margin: 0.5rem 1rem;
  }

  .option-label {
    height: 100%;
    width: 95%;
  }

  .option:hover {
    background: #f4f4f4;
  }

  .option-label:hover {
    cursor: pointer;
  }

  table {
    width: 100%;
    max-width: 32rem;
  }

  th {
    background-color: #f2f2f2;
    padding: 0.5rem 1rem;
  }

  td {
    padding: 0.5rem 1rem;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .tanggal {
    width: 100%;
  }

  .score {
    min-width: 6rem;
    border-top: 1px solid white;
  }
`

export const RightContainer = styled.div`
  width: 40%;

  .course-desc-wrap {
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    margin-top: 10px;
    margin-bottom: 15px;

    & > h2,
    & > p {
      padding: 0 15px;
    }

    & > h2 {
      padding-top: 10px;
      padding-bottom: 16px;
      border-bottom: 2px solid #dddddd;
    }

    & > p {
      padding-top: 12px;
    }
  }

  .class-overview {
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);

    .judul-content-right {
      padding: 16px;
      padding-bottom: 0;
      text-align: center;
    }

    .tutor-summary {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
`
