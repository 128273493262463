import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Layout from "../../../../components/layout"
import NoteComponent from "../../../../components/NoteComponent"
import Spinner from "../../../../components/Reusable/Spinner"
import SEO from "../../../../components/seo"
import * as sessionSelectors from "../../../../redux/slices/session/selectors"
import { getCourseById } from "../../../../services/course"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const NotePage = ({ user, params }) => {
  const [course, setCourse] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCourseById(params.course).then(resp => {
      setCourse(resp.data)
      setLoading(false)
    })
  }, [])

  return (
    <Layout>
      <SEO title="Catatan Tutor" />
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <NoteComponent course={course} itemId={params.note} />
      )}
    </Layout>
  )
}

export default withConnect(NotePage)
