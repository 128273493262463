import React from "react"
import { AiOutlineTrophy } from "@react-icons/all-files/ai/AiOutlineTrophy"
import styled from "styled-components"
import { ProgressBar } from "react-bootstrap"
import Button from "../Reusable/Button"
import { getCourseCertificateByCourseId } from "../../services/course"
import { navigate } from "gatsby"

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  .progress {
    height: 5px;
  }

  .progress-bar {
    background: #efa51d;
  }
`

const CourseProgress = ({ courseId, progress }) => {
  const checkCertificate = () => {
    getCourseCertificateByCourseId(courseId)
      .then(resp => navigate(`/certificate/${resp.data.id}`))
      .catch(err => {
        if (err.response.status === 404) {
          navigate(`/certificate/request/${courseId}`)
        }
      })
  }

  return (
    <Container>
      <div style={{ fontSize: "1.25rem" }}>
        <AiOutlineTrophy />
      </div>

      <div style={{ width: "100%" }}>
        <ProgressBar now={progress} />
      </div>

      <div>{progress}%</div>

      <Button
        className="secondary"
        disabled={progress < 100}
        onClick={checkCertificate}
      >
        Sertifikat
      </Button>
    </Container>
  )
}

export default CourseProgress
